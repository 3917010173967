import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import SEO from "../components/seo";
import sky from "../images/alyssa-smith-xNZHQn6lyLk-unsplash.jpg";
import ContentLayout from "../components/content-layout";

const Testimonial = ({ title, name, jobTitle, children }) => {
  return (
    <div className="w-full sm:w-1/2 -mx-2 flex flex-col items-start">
      <div className="mx-2">
        <h2 className="text-3xl text-gray-800">{title}</h2>
        <p>
          <strong className="mr-2">{name}</strong>
          <span>{jobTitle}</span>
        </p>
      </div>
      <div className="w-full mt-6 mx-2">
        <blockquote className="testimonial-quote">{children}</blockquote>
      </div>
    </div>
  );
};

const Testimonials = ({ data }) => {
  const testimonials = data.allMarkdownRemark.edges;
  return (
    <Layout headerClass="bg-white relative">
      <SEO title="Testimonials" />
      <ContentLayout title={`Testimonials`} image={sky}>
        <div className="w-full mt-6 md:mt-28 flex flex-row flex-wrap justify-between">
          {testimonials.map(({ node }) => {
            const { title, name, jobtitle, path } = node.frontmatter;
            const html = node.html;
            return (
              <Testimonial
                key={path}
                title={title}
                name={name}
                jobTitle={jobtitle}
              >
                <div dangerouslySetInnerHTML={{ __html: html }} />
              </Testimonial>
            );
          })}
        </div>
      </ContentLayout>
    </Layout>
  );
};

export const query = graphql`
  query Testimonials {
    allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/content/testimonials/" } }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      edges {
        node {
          frontmatter {
            title
            path
            name
            jobtitle
          }
          html
        }
      }
    }
  }
`;

export default Testimonials;
